
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import { I18nUtils } from "@/sea/utils/I18nUtils";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  props: {
    username: String,
    email: String,
    avatarUrl: String,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { locale } = useI18n();

    const countries = [
      {
        locale: "en",
        flag: "media/flags/new-zealand.svg",
        name: "English",
      },
      {
        locale: "zh-CN",
        flag: "media/flags/china.svg",
        name: "简体中文",
      },
    ];

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries.find((c) => c.locale === locale.value);
    });

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    return {
      countries,
      setLang,
      currentLanguage,
      currentLanguageLocale,
      I18nUtils,
      signOut,
    };
  },
});
